import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Grid } from 'react-styled-flexboxgrid';
import Carousel from 'react-multi-carousel';
import Layout from '../components/layout';
import SEO from '../components/seo';
import 'react-multi-carousel/lib/styles.css';
import Arrow from '../images/arrow-right.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 6000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const StyledCustomArrowRight = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 8;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  right: calc(2.4% + 1px);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill 0.5s ease;
  }
`;

const StyledCustomArrowLeft = styled(Arrow)`
  fill: var(--white);
  height: 43px;
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 8;
  border: 0;
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  left: calc(2.4% + 1px);
  transform: rotate(-180deg);

  &:hover,
  &:focus {
    fill: var(--blue);
    transition: fill 0.5s ease;
  }
`;

const ArrowRight = (arrowProps) => {
  const { carouselState, children, ...restArrowProps } = arrowProps;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledCustomArrowRight {...restArrowProps}>{children}</StyledCustomArrowRight>;
};

const ArrowLeft = (arrowProps) => {
  const { carouselState, children, ...restArrowProps } = arrowProps;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledCustomArrowLeft {...restArrowProps}>{children}</StyledCustomArrowLeft>;
};

const HeadingContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 37px;
`;

const StyledH1 = styled.h1`
  font-size: 4.625rem;
  letter-spacing: 2.2px;
  line-height: 5rem;
  color: var(--white);
  z-index: 99;

  @media (min-width: 64em) {
    font-size: 10rem;
    line-height: 9.75rem;
  }
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const StyledSliderImage = styled(Img)`
  width: 100%;
  height: 100vh;
`;

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  width: 100%;
`;

const IndexPage = ({ data, onClick }) => {
  const heading = data.datoCmsHome.heading.split(' ');
  heading.splice(1, 0, '<br />');
  const splitHeading = heading;

  return (
    <Layout>
      <SEO title="Home" />
      <SliderContainer>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay={data.datoCmsHome.carouselAutoPlay}
          autoPlaySpeed={data.datoCmsHome.carouselInterval}
          customRightArrow={
            <ArrowRight onClick={() => onClick()} />
          }
          customLeftArrow={<ArrowLeft onClick={() => onClick()} />}
        >
          {data.datoCmsHome.carousel.map(({ fluid, customData }) => (
            <div key={fluid}>
              {customData.projecturl && (
                <Link to={customData.projecturl}>
                  <StyledSliderImage fluid={fluid} />
                </Link>
              )}

              {!customData.projecturl && <StyledSliderImage fluid={fluid} />}
            </div>
          ))}
        </Carousel>
        <HeadingContainer>
          <StyledGrid fluid>
            <StyledH1
              dangerouslySetInnerHTML={{ __html: splitHeading.join('') }}
            />
          </StyledGrid>
        </HeadingContainer>
      </SliderContainer>
    </Layout>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query HomeQuery {
        datoCmsHome {
          carousel {
            fluid(
              maxWidth: 2000
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
            customData
          }
          carouselAutoPlay
          carouselInterval
          heading
        }
      }
    `}
    // eslint-disable-next-line react/jsx-props-no-spreading
    render={(data) => <IndexPage data={data} {...props} />}
  />
);

IndexPage.propTypes = {
  data: PropTypes.shape().isRequired,
  onClick: PropTypes.func,
};

IndexPage.defaultProps = {
  onClick: () => {},
};
